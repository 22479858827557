import React from 'react'
import tw from 'twin.macro' //eslint-disable-line
import { css } from 'styled-components/macro' //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'

import Hero from 'components/hero/BackgroundAsImage.js'
import Features from 'components/features/DashedBorderSixFeatures'
import MainFeature from 'components/features/TwoColSingleFeatureWithStats2.js'
import MainFeature2 from 'components/features/TwoColWithTwoFeaturesAndButtons.js'
import Portfolio from 'components/cards/PortfolioTwoCardsWithImage.js'
import Blog from 'components/blogs/ThreeColSimpleWithImageAndDashedBorder.js'
import Testimonial from 'components/testimonials/TwoColumnWithImageAndProfilePictureReview.js'
import FAQ from 'components/faqs/SimpleWithSideImage.js'
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustration.js'
import Footer from 'components/footers/MiniCenteredFooter.js'
import customerSupportIllustrationSrc from 'images/customer-support-illustration.svg'
import SimpleSubscribeNewsletter from 'components/forms/SimpleSubscribeNewsletter'
import TwoColContactUsWithIllustrationFullForm from 'components/forms/TwoColContactUsWithIllustrationFullForm'
import ScrollToTopButton from 'components/buttons/ScrollToTopButton'

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature />
    <Features id="services" />
    <MainFeature2 />
    <Portfolio />
    <Testimonial
      subheading="Client Testimonials"
      heading={
        <>
          What Our Clients <span tw="text-primary-500">Say About Us</span>
        </>
      }
      description="Don't just take our word for it. Here's what some of our valued clients have to say about their experience working with TechIT:"
      testimonials={[
        {
          imageSrc:
            'https://images.unsplash.com/photo-1560179707-f14e90ef3623?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
          quote:
            "TechIT's custom ERP solution has revolutionized our operations. We've seen a 30% increase in efficiency and a significant reduction in errors. Their team's expertise and support throughout the implementation were invaluable.",
          customerName: 'Sarah Johnson',
          customerTitle: 'COO, GlobalTech Solutions',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "The cybersecurity audit and subsequent improvements implemented by TechIT have given us peace of mind. Their team's thorough approach and clear communication throughout the process were exemplary.",
          customerName: 'Michael Chen',
          customerTitle: 'CTO, SecureBank',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1553028826-f4804a6dba3b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "TechIT's cloud migration services exceeded our expectations. They seamlessly transferred our infrastructure to AWS, resulting in improved scalability and significant cost savings. Their expertise in cloud technologies is unmatched.",
          customerName: 'Emily Rodriguez',
          customerTitle: 'Head of IT, CloudNine Enterprises',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            'The mobile app developed by TechIT for our healthcare service has been a game-changer. Patient engagement has increased by 40%, and the intuitive design has received overwhelmingly positive feedback.',
          customerName: 'Dr. James Wilson',
          customerTitle: 'Founder, HealthConnect',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "TechIT's data analytics solution has transformed our decision-making process. The insights generated have led to a 25% increase in sales and helped us identify new market opportunities.",
          customerName: 'Lisa Thompson',
          customerTitle: 'CMO, DataDriven Corp',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "The DevOps implementation by TechIT has dramatically improved our software delivery pipeline. We've reduced deployment times by 70% and significantly decreased the number of production issues.",
          customerName: 'Alex Patel',
          customerTitle: 'Lead Developer, FastTrack Software',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1531545514256-b1400bc00f31?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "TechIT's IT consulting services have been instrumental in our digital transformation journey. Their strategic guidance and technical expertise have helped us stay ahead of the curve in our industry.",
          customerName: 'Rachel Foster',
          customerTitle: 'CEO, InnovateNow',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "The UI/UX redesign of our e-commerce platform by TechIT has resulted in a 35% increase in conversion rates. Their design team's attention to detail and user-centric approach were impressive.",
          customerName: 'Daniel Lee',
          customerTitle: 'Product Manager, ShopSmart',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "TechIT's blockchain solution has revolutionized our supply chain management. We've achieved unprecedented levels of transparency and efficiency, resulting in cost savings and improved customer trust.",
          customerName: 'Olivia Martinez',
          customerTitle: 'Supply Chain Director, BlockChain Logistics',
        },
        {
          imageSrc:
            'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80',
          quote:
            "The digital marketing strategy developed by TechIT has significantly boosted our online presence. We've seen a 50% increase in organic traffic and a 40% improvement in lead generation within just six months.",
          customerName: 'Mark Anderson',
          customerTitle: 'Marketing Director, GrowthBoost',
        },
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    <Blog />
    <TwoColContactUsWithIllustrationFullForm />
    <ContactUsForm />
    <SimpleSubscribeNewsletter />
    <Footer />
    <ScrollToTopButton />
  </AnimationRevealPage>
)
