import React, { Fragment, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js'
import { SubmitButton } from 'components/misc/Buttons.js'
import EmailIllustrationSrc from 'images/email-illustration.svg'
import axios from 'axios'
import { motion } from 'framer-motion'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
])
const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: 'textarea' })`
  ${tw`h-24`}
`

const SuccessMessage = tw(
  motion.div
)`bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-8`

export default ({
  subheading = 'Get in Touch',
  heading = (
    <>
      Reach out to <span tw="text-primary-500">us</span>
      <wbr /> today.
    </>
  ),
  description = 'We value your feedback and look forward to hearing from you.',
  submitButtonText = 'Submit',
  formAction = '#',
  formMethod = 'post',
  textOnLeft = true,
}) => {
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')
  const [subjectError, setSubjectError] = useState('')
  const [messageError, setMessageError] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [apiError, setApiError] = useState('')
  const [isLoading, setIsLoading] = useState(false) // Add loading state

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/ // E.164 format
    return phoneRegex.test(phone)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formProps = Object.fromEntries(formData)

    // Validate fields
    let hasError = false

    if (!validateEmail(formProps.email)) {
      setEmailError('Please enter a valid email address')
      hasError = true
    } else {
      setEmailError('')
    }

    if (!formProps.name) {
      setNameError('Please enter your name')
      hasError = true
    } else {
      setNameError('')
    }

    if (!formProps.subject) {
      setSubjectError('Please enter a subject')
      hasError = true
    } else {
      setSubjectError('')
    }

    if (!formProps.message) {
      setMessageError('Please enter a message')
      hasError = true
    } else {
      setMessageError('')
    }

    if (formProps.phoneNumber && !validatePhone(formProps.phoneNumber)) {
      setPhoneError('Please enter a valid phone number')
      hasError = true
    } else {
      setPhoneError('')
    }

    if (hasError) return
    setIsLoading(true) // Set loading state to true before API call
    try {
      setShowSuccessMessage(false)
      setApiError('')
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contact`,
        formProps
      )
      setShowSuccessMessage(true)
      e.target.reset()
      setTimeout(() => setShowSuccessMessage(false), 5000)
    } catch (error) {
      setApiError(
        error.response?.data?.error ||
          'Error submitting form. Please try again.'
      )
    } finally {
      setIsLoading(false) // Reset loading state after API call
    }
  }

  return (
    <Container id="ContactUs">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit} noValidate>
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                required
                onChange={(e) => {
                  if (!validateEmail(e.target.value)) {
                    setEmailError('Please enter a valid email address')
                  } else {
                    setEmailError('')
                  }
                }}
              />
              {emailError && <p tw="text-red-500 text-sm mt-1">{emailError}</p>}
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                required
                onChange={(e) => {
                  if (!e.target.value) {
                    setNameError('Please enter your name')
                  } else {
                    setNameError('')
                  }
                }}
              />
              {nameError && <p tw="text-red-500 text-sm mt-1">{nameError}</p>}
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                required
                onChange={(e) => {
                  if (!e.target.value) {
                    setSubjectError('Please enter a subject')
                  } else {
                    setSubjectError('')
                  }
                }}
              />
              {subjectError && (
                <p tw="text-red-500 text-sm mt-1">{subjectError}</p>
              )}
              <Input
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                onChange={(e) => {
                  if (e.target.value && !validatePhone(e.target.value)) {
                    setPhoneError('Please enter a valid phone number')
                  } else {
                    setPhoneError('')
                  }
                }}
              />
              {phoneError && <p tw="text-red-500 text-sm mt-1">{phoneError}</p>}
              <Textarea
                name="message"
                placeholder="Your Message Here"
                required
                onChange={(e) => {
                  if (!e.target.value) {
                    setMessageError('Please enter a message')
                  } else {
                    setMessageError('')
                  }
                }}
              />
              {messageError && (
                <p tw="text-red-500 text-sm mt-1">{messageError}</p>
              )}
              {apiError && <p tw="text-red-500 text-sm mt-1">{apiError}</p>}
              <SubmitButton
                type="submit"
                disabled={
                  emailError ||
                  nameError ||
                  subjectError ||
                  phoneError ||
                  messageError ||
                  isLoading // Disable button when loading
                }
              >
                {isLoading ? 'Loading...' : submitButtonText}{' '}
                {/* Show loading text */}
              </SubmitButton>
            </Form>
            {showSuccessMessage && (
              <SuccessMessage
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Thanks for reaching out, we will get back to you soon!
              </SuccessMessage>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
