import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import tw from 'twin.macro'
import styled from 'styled-components' //eslint-disable-line
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import MainFeature1 from 'components/features/TwoColWithButton.js'
import Features from 'components/features/ThreeColSimple.js'
import TeamCardGrid from 'components/cards/ProfileThreeColGrid.js'

import SupportIconImage from 'images/support-icon.svg'
import ShieldIconImage from 'images/shield-icon.svg'
import CustomerLoveIconImage from 'images/simple-icon.svg'

const Subheading = tw.span`uppercase tracking-wider text-sm`
export default () => {
  return (
    <AnimationRevealPage>
      <Header showNavLinks={false} />
      <MainFeature1
        subheading={<Subheading>About Us</Subheading>}
        heading="We are a forward-thinking tech company."
        buttonRounded={true}
        primaryButtonText="Learn More"
        imageSrc="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
        showTooltip
      />
      <MainFeature1
        subheading={<Subheading>Our Mission</Subheading>}
        heading="Innovating for a better future."
        buttonRounded={true}
        primaryButtonText="Join Us"
        imageSrc="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
        textOnLeft={true}
        showTooltip
      />
      <Features
        subheading={<Subheading>Core Values</Subheading>}
        heading="What drives us."
        description="We believe in innovation, integrity, and customer satisfaction."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: '24/7 Support',
            description:
              'We are always here to help you with any issues or questions.',
          },
          {
            imageSrc: ShieldIconImage,
            title: 'Security',
            description:
              'We prioritize the security and privacy of our customers.',
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: 'Customer Focus',
            description: 'Our customers are at the heart of everything we do.',
          },
        ]}
        linkText="Learn More"
        showtooltip
      />
      <TeamCardGrid subheading={<Subheading>Meet the Team</Subheading>} />
      {/* <Footer /> */}
    </AnimationRevealPage>
  )
}
