import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js'
import { ReactComponent as ChatIcon } from 'feather-icons/dist/icons/message-circle.svg'

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const Image = styled.div`
  ${tw`rounded bg-contain bg-no-repeat bg-center h-full`}
  svg {
    ${tw`w-full h-full`}
  }
`

const TextContent = tw.div`lg:py-8 text-center md:text-left`

const Subheading = tw(SubheadingBase)`text-center md:text-left`
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const SubmitButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block bg-green-500 text-white hocus:bg-green-700`

export default ({
  subheading = 'Contact Us',
  heading = (
    <>
      Ready to <span tw="text-primary-500">chat</span> on WhatsApp?
    </>
  ),
  description = "Get in touch with our team instantly via WhatsApp. We're here to answer your questions and provide quick support for all your IT needs.",
  buttonText = 'Open WhatsApp Chat',
  textOnLeft = true,
}) => {
  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/916362376189`, '_blank')
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image>
            <ChatIcon />
          </Image>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <SubmitButton onClick={handleWhatsAppClick}>
              {buttonText}
            </SubmitButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}
