import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import techitadBanner from '../../images/techitadBanner.jpg'; 
import { css } from 'styled-components/macro' 

import Header, {
  NavLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from '../headers/light.js'
import ResponsiveVideoEmbed from '../../helpers/ResponsiveVideoEmbed.js'

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${techitadBanner}), url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80"); // Use the imported image as overlay
`

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`
const LeftColumn = tw.div`flex flex-col items-center lg:block`
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  
  &::before {
    content: '';
    ${tw`absolute inset-0 transform -skew-x-12 -z-10`}
    // ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
    background-color: rgba(211, 211, 211, ${props => props.opacity || 0.7}); // Light gray with custom opacity based on input
  }
`

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`

const PrimaryAction = styled.a`
  ${tw`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`}
  position: relative;
  overflow: hidden;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
    transition: all 0.6s;
  }

  &:hover::before {
    left: 100%;
  }
`

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <TwoColumn>
          <LeftColumn>
            <Notification css={tw`bg-blue-500 bg-opacity-50 text-white p-4 rounded shadow-lg`}>
              We have now launched operations in India.
            </Notification>
            <Heading>
              <span>Hire the best</span>
              <br />
              <SlantedBackground>
                Transform Your Business with Custom IT Solutions
              </SlantedBackground>
              <SlantedBackground>
              <span tw="text-xl text-gray-900 font-normal">
                Explore how our tech expertise can drive your business forward.
                Get a custom quote or speak with a specialist to see how we can
                help you achieve your goals.
              </span>
              </SlantedBackground>
            </Heading>
            <PrimaryAction
              onClick={() =>
                document
                  .getElementById('ContactUs')
                  .scrollIntoView({ behavior: 'smooth' })
              }
            >
              Schedule a Free Consultation
            </PrimaryAction>
          </LeftColumn>
          {/* <RightColumn>
            <StyledResponsiveVideoEmbed
              url='//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1'
              background='transparent'
              style={{ width: '100%', height: 'auto' }} // Ensure responsiveness
            />
          </RightColumn> */}
        </TwoColumn>
      </HeroContainer>
    </Container>
  )
}
